import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { accountActions } from "../_actions/account.actions";
import { Loader } from "../_theme/components/loader";

import { Button, Container, TextField, Typography } from "@material-ui/core";

function LoginForm() {
  const [inputs, setInputs] = useState({
    username: "",
    password: ""
  });
  const { username, password } = inputs;
  const loggingIn = useSelector(state => state.authentication.loggingIn);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(accountActions.logout());
  }, []);

  function changeHandler(event) {
    const { name, value } = event.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  function submitHandler(event) {
    event.preventDefault();

    if (username && password) {
      dispatch(accountActions.login(username, password));
    }
  }

  return (
    <Container maxWidth="xs">
      <Typography variant="h5">Sign in</Typography>

      {loggingIn ? (
        <Loader />
      ) : (
        <form onSubmit={submitHandler}>
          <TextField
            type="text"
            name="username"
            value={username}
            onChange={changeHandler}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoFocus
            label="Username"
          />

          <TextField
            type="password"
            name="password"
            value={password}
            onChange={changeHandler}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
          />

          <Button type="submit" fullWidth variant="contained" color="primary">
            Login
          </Button>
        </form>
      )}
    </Container>
  );
}

// LoginForm.PropTypes = {
// }

export default LoginForm;
