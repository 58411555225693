import React, {useEffect} from "react";
import {useHistory} from 'react-router-dom';
import DataTable from "../../_components/DataTable";
import {useDispatch, useSelector} from "react-redux";
import {alertActions, clientsActions, surfaceActions} from "../../_actions";
import FloatingActionButton from "../../_components/FloatingActionButton";


const ClientList = () => {
  const clients = useSelector((state) => state.clients);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(surfaceActions.setPageTitle("Client List"));
    refresh();
  }, []);

  function refresh() {
    dispatch(clientsActions.getList());
    dispatch(alertActions.clear());
  }

  /**Functions for action menus **/
  const viewClient = (closeHandler, clientId) => {
    closeHandler();
    alert(clientId);
  }
  const editClient = (closeHandler, clientId) => {
    closeHandler();
    history.push(`/admin/client/update/${clientId}`);
  }
  const inActivateClient = (closeHandler, clientId) => {
    closeHandler();
    alert(clientId);
  }
  const deleteClient = (closeHandler, clientId) => {
    closeHandler();
    alert(clientId);

  }


  const columns = [
    {
      header: "Name",
      field: "Name",
    },
    {
      header: "Descriptions",
      field: "Description",
    },
    {
      header: "Actions",
      field: "sk",
      disableSorting: true,
      actions: [
        {clickListener: viewClient, label: 'View'},
        {clickListener: editClient, label: 'Edit'},
        {clickListener: inActivateClient, label: 'Inactivate'},
        {clickListener: deleteClient, label: 'Delete'}
      ],
      parameters: {
        clientId: "#field#",
      },
    },
  ];

  return (
    <div>
      {
        clients ?
            <DataTable dataCollection={clients} columns={columns} ></DataTable>
            : null
      }

      <FloatingActionButton path={'/admin/client/new'}/>

    </div>
  );
}

export default ClientList;
