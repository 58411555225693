import {authHeader} from "../_helpers";
import {handleResponse} from "./helper.service";
import config from "../config";
function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${config.getAdminApiBaseUrl()}/devices`, requestOptions).then(
      handleResponse
  );
}

function getSingle(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${config.getAdminApiBaseUrl()}/devices/${id}`, requestOptions).then(
      handleResponse
  );
}

const save = (deviceJson, isUpdate) => {

  let url = `${config.getAdminApiBaseUrl()}/devices`;

  if (isUpdate) {
    url = `${url}/${deviceJson.SerialNumber}`;
    // converting into desired format
    //e.g {update: {key:value}}
    deviceJson = {
      update: {
        ...deviceJson
      }
    }
  }

  const requestOptions = {
    method: isUpdate ? 'PUT': 'POST',
    headers: authHeader(),
    body: JSON.stringify(deviceJson)
  }
  return fetch(url, requestOptions)
      .then(handleResponse)
}

export const devicesService = {
  getAll,
  getSingle,
  save
};
