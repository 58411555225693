import React from "react";
import {
  makeStyles,
  Toolbar,
  AppBar,
  IconButton,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import AppsMenu from "./AppsMenu";
import { useDispatch } from "react-redux";
import { surfaceActions } from "../_actions";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  grow: {
    flexGrow: 1,
  },
}));

function TopAppBar() {
  const dispatch = useDispatch();

  const handleAppDrawer = () => {
    dispatch(surfaceActions.toggleDrawer());
  };

  const classes = useStyles();
  return (
    <AppBar position="absolute" color="primary" className={classes.appBar}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          className={classes.menuButton}
          onClick={handleAppDrawer}
        >
          <MenuIcon />
        </IconButton>
        <Typography className={classes.title} variant="h6" noWrap>
          BIRD
        </Typography>

        <div className={classes.grow} />

        <AppsMenu />
      </Toolbar>
    </AppBar>
  );
}

export default TopAppBar;
