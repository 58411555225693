const common = {
    mapsKey: process.env.REACT_APP_MAPS_KEY
};

const aws = {
    ...common,
    getAdminApiBaseUrl: () => `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_STAGE}-admin`,
    getDeviceApiBAseUrl: () => `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_STAGE}-device`,
    getUserApiBAseUrl: () => `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_STAGE}-user`,
};

const local = {
    ...common,
    getAdminApiBaseUrl: () => process.env.REACT_APP_API_URL,
    getDeviceApiBAseUrl: () => process.env.REACT_APP_API_URL,
    getUserApiBAseUrl: () => process.env.REACT_APP_API_URL
};

const config = process.env.REACT_APP_STAGE === 'local' ? local : aws;

export default config;