import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";
import GoogleMapReact from "google-map-react";
import LocationIcon from "../Common/locationIcon";
import config from "../config";

const AnyReactComponent = () => (
  <div>
    <LocationIcon />
  </div>
);

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    minHeight: "30vh",
    width: "100%",
  },
}));

function SingleLocationMap(props) {
  const { device, ...other } = props;

  const defaultProps = {
    center: { lat: 40.744679, lng: -73.948542 },
    zoom: 11,
  };

  const classes = useStyles();

  return (
    // <div className={classes.root}>
    <GoogleMapReact
      bootstrapURLKeys={config.mapsKey}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
    >
      {/* <AnyReactComponent lat={40.747331} lng={-73.851744} /> */}
    </GoogleMapReact>
    // </div>
  );
}

SingleLocationMap.PropType = {
  device: PropTypes.object.isRequired,
};

export default SingleLocationMap;
