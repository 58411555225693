import { tokenLocalStorageName, handleResponse } from "./helper.service";
import config from "../config";

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ user: username, password }),
  };

  return fetch(`${config.getAdminApiBaseUrl()}/login`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      localStorage.setItem(
        tokenLocalStorageName,
        JSON.stringify(response["token"])
      );

      return response;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem(tokenLocalStorageName);
}

export const accountService = {
  login,
  logout,
};
