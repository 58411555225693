import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  IconButton,
  Popper,
  Paper,
  Fade
} from "@material-ui/core";
import LocationOn from "@material-ui/icons/LocationOn";
import SingleLocationMap from "../Devices/SingleLocationMap";

const useStyles = makeStyles(theme => ({
  root: {},
  locationButton: {
    padding: "6px"
  },
  mapPopper: {
    width: "400px",
    height: "280px"
  },
  mapPaper: {
    padding: theme.spacing(1)
  }
}));

function LocationIcon(props) {
  const { longitude, latitude, device, showMapOnHover, ...other } = props;

  if (!(longitude && latitude) && device) {
    longitude = device.longitude;
    latitude = device.latitude;
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const showMap = event => {
    setAnchorEl(event.currentTarget);
    setOpen(prev => !prev);
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <IconButton
        color="primary"
        className={classes.locationButton}
        onMouseOver={showMap}
        onMouseOut={showMap}
        // onMouseOver={() => setOpen(true)}
        // onMouseOut={() => setOpen(false)}
      >
        <LocationOn />
      </IconButton>
      {showMapOnHover && (
        <Popper
          open={open}
          anchorEl={anchorEl}
          transition
          className={classes.mapPopper}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.mapPaper}>
                ID {device && device.SerialNumber}
                <div style={{ height: "30vh", width: "100%" }}>
                  <SingleLocationMap device={device} />
                </div>
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
    </div>
  );
}

LocationIcon.PropType = {
  longitude: PropTypes.number.isRequired,
  latitude: PropTypes.number.isRequired
};

export default LocationIcon;
