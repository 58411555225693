import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, IconButton, MenuItem, Menu } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles(theme => ({
  moreButton: {
    padding: "6px"
  }
}));

function ActionsMenu(props) {
  const { deviceId, ...other } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function locateDevice() {
    handleClose();
  }
  function sendMessage() {
    handleClose();
  }
  function rebootDevice() {
    handleClose();
  }

  const classes = useStyles();
  return (
    <div>
      <IconButton onClick={handleClick} className={classes.moreButton}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={locateDevice}>Locate Device</MenuItem>
        <MenuItem onClick={sendMessage}>Send Message to Device</MenuItem>
        <MenuItem onClick={rebootDevice}>Reboot Device</MenuItem>
      </Menu>
    </div>
  );
}

ActionsMenu.PropType = {
  deviceId: PropTypes.string.isRequired
};

export default ActionsMenu;
