import { authHeader } from "../_helpers/auth-header";
import { handleResponse } from "./helper.service";
import config from "../config";

function getAll() {
  // TODO
  return getDeviceSpecific(1);
}

function getDeviceSpecific(deviceSerial, startDate, endDate) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${config.getDeviceApiBAseUrl()}/device/alarms/${deviceSerial}`,
    requestOptions
  ).then(handleResponse);
}

function getLocationSpecific(locationId) {
  // TODO
  return getDeviceSpecific(1);
}

export const alarmsService = {
  getAll,
  getDeviceSpecific,
  getLocationSpecific,
};
