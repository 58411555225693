import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { userActions, alarmsActions } from "../_actions";
import {
  makeStyles,
  Typography,
  Box,
  Paper,
  Grid,
  GridList,
  GridListTile,
} from "@material-ui/core";
import DataTable from "../Common/dataTable";
import InfoCard from "./InfoCard";
import IsOnlineBadge from "../Devices/IsOnlineBadge";
import ActionsMenu from "./ActionsMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  cardsGridList: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  alarmsPaper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    alignItems: "center",
  },
}));

function DashboardPage() {
  const alarms = useSelector((state) => state.alarms);
  const dispatch = useDispatch();

  function refreshAlarms() {
    dispatch(alarmsActions.getAll());
  }

  useEffect(() => {
    // refreshAlarms();
  }, []);
  const classes = useStyles();

  const columns = [
    {
      header: "Id",
      field: "id",
    },
    {
      header: "Battery",
      field: "attributes.AlarmResolution",
    },
    {
      header: "Narcotics Alert",
      field: "attributes.AlarmResolution",
    },
    {
      header: "Explosive Alerts",
      field: "attributes.AlarmResolution",
    },
    {
      header: "Narcotics Material",
      field: "attributes.AlarmResolution",
    },
    {
      header: "Explosive Material",
      field: "attributes.AlarmResolution",
    },
    {
      header: "Online",
      field: "",
      template: IsOnlineBadge,
      parameters: {
        //   isOnline: "#field#",
        isOnline: Math.random() >= 0.5,
        iconOnly: true,
      },
    },
    {
      header: "Actions",
      template: ActionsMenu,
      parameters: {},
    },
  ];

  return (
    <div className={classes.root}>
      <Typography variant="h5">Dashboard</Typography>

      <Grid
        container
        spacing={2}
        wrap="nowrap"
        direction="row"
        justify="space-between"
        alignItems="stretch"
        className={classes.cardsGridList}
      >
        <Grid item xs>
          <InfoCard title="Current Allerts" text="275" />
        </Grid>
        <Grid item xs>
          <InfoCard title="Total Devices" text="8113" />
        </Grid>
        <Grid item xs>
          <InfoCard title="Offline" text="57" />
        </Grid>
        <Grid item xs>
          <InfoCard title="Explosive Allers" text="123" />
        </Grid>
        <Grid item xs>
          <InfoCard title="Narcotics Allerts" text="125" />
        </Grid>
      </Grid>

      <Paper className={classes.alarmsPaper}>
        <DataTable
          dataCollection={alarms}
          columns={columns}
          title="Alarms"
          isDense={true}
          refreshCommand={refreshAlarms}
        />
      </Paper>
    </div>
  );
}

export default DashboardPage;
