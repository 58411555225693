import {authHeader} from "../_helpers";
import {handleResponse} from "./helper.service";
import config from "../config";

function getAll() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.getAdminApiBaseUrl()}/regions`, requestOptions).then(
        handleResponse
    );
}

function getSingle(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.getAdminApiBaseUrl()}/regions/${id}`, requestOptions).then(
        handleResponse
    );
}


const save = (regionJson, isUpdate) => {
    let url;

    if (isUpdate) {
        url = `${config.getAdminApiBaseUrl()}/regions/${regionJson.RegionId}`;
        // converting into desired format
        //e.g {update: {key:value}}
        regionJson = {
            update: {
                ...regionJson
            }
        }
    } else {
        url = `${config.getAdminApiBaseUrl()}/regions`;
    }

    const requestOptions = {
        method: isUpdate ? 'PUT' : 'POST',
        headers: authHeader(),
        body: JSON.stringify(regionJson)
    }


    return fetch(url, requestOptions)
        .then(handleResponse)
}

export const regionsService = {
    getAll,
    getSingle,
    save
};
