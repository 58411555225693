import React, {useEffect} from "react";
import {useHistory} from 'react-router-dom';
import DataTable from "../../_components/DataTable";
import {useDispatch, useSelector} from "react-redux";
import {alertActions, deviceActions, surfaceActions} from "../../_actions";
import FloatingActionButton from "../../_components/FloatingActionButton";


const DeviceList = () => {
    const devices = useSelector((state) => state.devices);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(surfaceActions.setPageTitle("Device List"));
        refresh();
    }, []);

    function refresh() {
        dispatch(deviceActions.getAll());
        dispatch(alertActions.clear());
    }

    /**Functions for action menus **/
    const viewUser = (closeHandler, serialNumber) => {
        closeHandler();
        alert(serialNumber);
    }

    const viewOnMap = (closeHandler, serialNumber) => {
        closeHandler();
        alert(serialNumber);
    }

    const editUser = (closeHandler, serialNumber) => {
        closeHandler();
        history.push(`/admin/device/update/${serialNumber}`);
    }
    const inActivateUser = (closeHandler, serialNumber) => {
        closeHandler();
        alert(serialNumber);
    }
    const deleteUser = (closeHandler, serialNumber) => {
        closeHandler();
        alert(serialNumber);

    }


    const columns = [
        {
            header: "Device",
            field: "SerialNumber",
        },
        {
            header: "Mode",
        },
        {
            header: "Client",
            field: "Client.Name",
        },
        {
            header: "Assigned to Site",
            field: "Site.Name",
        },
        {
            header: "Description",
            field: "Description",
        },
        {
            header: "Actions",
            field: "SerialNumber",
            disableSorting: true,
            actions: [
                {clickListener: viewUser, label: 'View'},
                {clickListener: editUser, label: 'Edit'},
                {clickListener: inActivateUser, label: 'Inactivate'},
                {clickListener: deleteUser, label: 'Delete'}
            ],
            parameters: {
                clientId: "#field#",
            },
        },
    ];

    return (
        <div>
            {
                devices ?
                    <DataTable dataCollection={devices} columns={columns}></DataTable>
                    : null
            }

            <FloatingActionButton path={'/admin/device/new'}/>

        </div>
    );
}

export default DeviceList ;
