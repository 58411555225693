import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alarmsActions } from "../_actions/alarms.actions";
import { makeStyles } from "@material-ui/core";
import DataTable from "../Common/dataTable";
import IsOnlineBadge from "../Devices/IsOnlineBadge";
import LocationIcon from "../Common/locationIcon";

const useStyles = makeStyles({});

function AlarmsLocationSpecific(props) {
  const { locationId, ...other } = props;

  const alarms = useSelector(state => state.alarms);
  const dispatch = useDispatch();

  useEffect(() => {
    refresh();
  }, []);

  function refresh() {
    dispatch(alarmsActions.getByLocation(locationId));
  }

  const classes = useStyles();

  const columns = [
    {
      header: "Date and Time",
      field: "attributes.DateTime"
    },
    {
      header: "Type",
      field: "attributes.Type"
    },
    {
      header: "Id",
      field: "attributes.SerialNumber"
    },
    {
      header: "Status",
      field: "",
      template: IsOnlineBadge,
      parameters: {
        // isOnline: "#field#",
        isOnline: Math.random() >= 0.5,
        iconOnly: false
      }
    },
    {
      header: "Location",
      field: "attributes.Coordinates",
      template: LocationIcon,
      parameters: {
        longitude: 56.63214,
        latitude: 53.88486,
        showMapOnHover: true
      }
    },
    {
      header: "Photo",
      field: "attributes.ItemPhoto"
    }
  ];

  return (
    <div>
      <DataTable
        dataCollection={alarms}
        columns={columns}
        refreshCommand={refresh}
      />
    </div>
  );
}

export default AlarmsLocationSpecific;
