import React, {Fragment, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from "@material-ui/core";
import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers';
import {clientsActions, deviceActions, sitesActions, surfaceActions} from "../../_actions";
import BackLinkButton from "../../_components/BackLinkButton";
import Field from "../../_components/Field";
import {FieldTypes} from "../../Common/constants";
import SubmitButton from "../../_components/SubmitButton";
import {getClientOptions, getSiteOptions, getSitesByClient} from "../../Common/helpers";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles((theme) => ({
    fieldContainer: {
        textAlign: 'center'
    }
}));


const AddDevice = ({location}) => {

    const clients = useSelector(state => state.clients.items);
    const sites = useSelector(state => state.sites.items);
    const deviceObj = useSelector(state => state.devices.item);
    const isUpdateFlow = location.pathname.includes('update');
    const {deviceId} = useParams();
    const dispatch = useDispatch();
    const classes = useStyles();

    const validationRules = {
        SerialNumber: yup.string().required('Device ID is required'),
        Description: yup.string().required('Description is required'),
        DeviceInformationInterval: yup.string().required('Information Interval is required'),
        DeviceMeasurementInterval: yup.string().required('Measurement Interval is required'),
        DeviceServiceInterval: yup.string().required('Service Interval is required'),
        ManualDataRequestInterval: yup.string().required('Manual Request Interval is required'),
        Client: yup.mixed().required('Client is required'),
        User: yup.string().required('Username is required'),
        Password: yup.string().required('Password is required')
    }

    if (isUpdateFlow) {
        delete validationRules.Password;
    }

    const validationSchema = yup.object().shape(validationRules);

    const {register, handleSubmit, errors, control, formState, reset, watch} = useForm({
        defaultValues: {
            Site: null,
            SerialNumber: '',
            Description: '',
            DeviceInformationInterval: 0,
            DeviceMeasurementInterval: 0,
            DeviceServiceInterval: 0,
            ManualDataRequestInterval: 0,
            Client: null,
            Password: '',
            User: ''
        },
        mode: 'onTouched',
        resolver: yupResolver(validationSchema),

    });

    const watchClient = watch('Client');
    const submitHandler = (data) => {
        data['Client'] = data.Client?.ClientId;
        data['Site'] = data.Site ? data.Site.SiteId : null;
        if (deviceId) {
            data['SerialNumber'] = deviceId;
            dispatch(deviceActions.save(data, true));
        } else {
            dispatch(deviceActions.save(data, false));
        }
    };


    //Setting loaded device object for update case
    useEffect(() => {
        if (deviceObj) {
            if (deviceObj && !deviceObj.Site) {
                deviceObj['Site'] = null;
            }
            reset(deviceObj);
        }
    }, [deviceObj])


    useEffect(() => {
        dispatch(sitesActions.getList());
        dispatch(clientsActions.getList());
        if (deviceId) {
            dispatch(deviceActions.getSingle(deviceId));
            dispatch(surfaceActions.setPageTitle("Update Device"));
        } else {
            dispatch(surfaceActions.setPageTitle("Add Device"));
        }
    }, [])


    return (

        <Fragment>
            <Box>
                <BackLinkButton path={'/admin/devices'}/>
                <Container maxWidth='sm'>
                    <form onSubmit={handleSubmit(submitHandler)} autoComplete='off'>
                        <Box className={classes.fieldContainer}>
                            <Field
                                type={FieldTypes.TEXT_FIELD}
                                name='SerialNumber'
                                label='Device ID'
                                register={register}
                                errors={errors}
                                isUpdateFlow={isUpdateFlow}
                            />
                            <Field
                                type={FieldTypes.TEXT_FIELD}
                                name='User'
                                label='Username'
                                register={register}
                                errors={errors}
                                isUpdateFlow={isUpdateFlow}
                            />
                            {
                                !isUpdateFlow &&
                                <Field
                                    type={FieldTypes.PASSWORD_FIELD}
                                    name='Password'
                                    label='Password'
                                    register={register}
                                    errors={errors}
                                    isUpdateFlow={isUpdateFlow}
                                />
                            }
                            <Field
                                type={FieldTypes.SELECT_FIELD}
                                name='Client'
                                control={control}
                                errors={errors}
                                dropdownOptions={getClientOptions(clients)}
                                dropdownOptionLabel={option => option.Name}
                                dropdownOptionSelected={(option, value) => option.ClientId === value.ClientId}
                                label='Select Client'
                            />
                            <Field
                                type={FieldTypes.SELECT_FIELD}
                                name='Site'
                                control={control}
                                errors={errors}
                                dropdownOptions={(watchClient) ? getSitesByClient(sites, watchClient.ClientId) : getSiteOptions(sites)}
                                dropdownOptionLabel={option => option.Name}
                                dropdownOptionSelected={(option, value) => option && value && option.SiteId === value.SiteId}
                                label='Assigned to Site'
                            />
                            <Field
                                type={FieldTypes.TEXT_AREA}
                                name='Description'
                                register={register}
                                errors={errors}
                                isUpdateFlow={isUpdateFlow}
                            />
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        type={FieldTypes.NUMBER_FIELD}
                                        name='DeviceInformationInterval'
                                        register={register}
                                        errors={errors}
                                        label='Information Interval'
                                        isUpdateFlow={isUpdateFlow}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        type={FieldTypes.NUMBER_FIELD}
                                        name='DeviceMeasurementInterval'
                                        register={register}
                                        errors={errors}
                                        label='Measurement Interval'
                                        isUpdateFlow={isUpdateFlow}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        type={FieldTypes.NUMBER_FIELD}
                                        name='DeviceServiceInterval'
                                        register={register}
                                        errors={errors}
                                        label='Service Interval'
                                        isUpdateFlow={isUpdateFlow}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        type={FieldTypes.NUMBER_FIELD}
                                        name='ManualDataRequestInterval'
                                        register={register}
                                        errors={errors}
                                        label='Manual Request Interval'
                                        isUpdateFlow={isUpdateFlow}
                                    />
                                </Grid>
                            </Grid>
                            <SubmitButton
                                formState={formState}
                                label={isUpdateFlow ? 'UPDATE' : 'ADD'}
                            />
                        </Box>
                    </form>
                </Container>
            </Box>

        </Fragment>
    );
};

export default AddDevice;