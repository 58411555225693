import {clientsConstants} from "../_constants";
import {clientsService} from "../_services";
import {history} from "../_helpers";
import {alertActions, loaderActions} from './'

function getList() {

    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        clientsService.getAll().then(
            (response) => {
                const clients = response.data ? response.data.Items : [];
                dispatch(getAllSuccess(clients))
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatchError(error, dispatch);
            }
        );
    };
}


function getSingle(id) {
    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        clientsService.getSingle(id).then(
            (response) => {
                dispatch(getSingleSuccess(response));
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatchError(error, dispatch);
            }
        );
    };

}

function save(data, isUpdate) {
    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        clientsService.save(data, isUpdate).then(
            (response) => {
                dispatch(saveSuccess(response));
                history.push('/admin/clients');
                const message = isUpdate ? 'Client updated successfully' : 'Client saved successfully';
                dispatch(alertActions.success(message));
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatchError(error, dispatch);
            }
        );
    };
}

function dispatchError(error, dispatch) {
    dispatch(failure(error.toString()));
    dispatch(alertActions.error(error.toString()));
    dispatch(loaderActions.clear());
}


function getAllSuccess(clients) {
    return {type: clientsConstants.GET_ALL_SUCCESS, clients};
}

function saveSuccess(client) {
    return {type: clientsConstants.SAVE_SUCCESS, client};
}

function getSingleSuccess(client) {
    return {type: clientsConstants.GET_SINGLE_SUCCESS, client};
}

function failure(error) {
    return {type: clientsConstants.FAILURE, error};
}


export const clientsActions = {
    getList,
    save,
    getSingle,
};
