import React, {useEffect} from "react";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {history} from "./_helpers";
import {alertActions, loaderActions} from "./_actions";
import PrivateRoute from "./_components/PrivateRoute";
import DashboardPage from "./Home/DashboardPage";
import LocationOverviewPage from "./Home/LocationOverviewPage";
import LoginForm from "./Account/LoginForm";
import DeviceOverviewPage from "./Devices/DeviceOverviewPage";
import AlertSnackbar from "./Common/alertSnackbar";
import DevicesTableView from "./Devices/DevicesTableView";
import FlowAdmin from "./Admin/FlowAdmin";
import AdminRoute from "./_components/AdminRoute";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function App() {
  const alert = useSelector((state) => state.alert);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
      dispatch(loaderActions.clear())
    });
  }, []);

  return (
    <div>
      {alert.message  && <AlertSnackbar message={alert.message} type={alert.type} />}

      <Backdrop className={classes.backdrop} open={loader.isLoading} >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Router history={history}>
        <Switch>
          <PrivateRoute exact path="/" component={DashboardPage} />
          <PrivateRoute
            exact
            path="/location/:locationId"
            component={LocationOverviewPage}
          />
          <PrivateRoute
              exact
              path="/devices"
              component={DevicesTableView}
          />
          <PrivateRoute
            exact
            path="/device/:deviceId"
            component={DeviceOverviewPage}
          />
          <Route path="/login" component={LoginForm} />

          <AdminRoute path='/admin' component={FlowAdmin}/>

          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    </div>
  );
}

export { App };
