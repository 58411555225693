import React from "react";
import { Route, Redirect } from "react-router-dom";
import { makeStyles, CssBaseline, AppBar, Toolbar } from "@material-ui/core";
import AppDrawer from "../Surface/AppDrawer";
import TopAppBar from "../Surface/TopAppBar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <TopAppBar />
      <AppDrawer />
      <main className={classes.content}>
        <Toolbar />
         <Route
          {...rest}
          render={(props) =>
            localStorage.getItem("token") ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            )
          }
        />
      </main>
    </div>
  );
};

export default PrivateRoute;
