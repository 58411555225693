import { accountConstants } from "../_constants";
import { accountService } from "../_services";
import { alertActions } from "./";
import { history } from "../_helpers";

export const accountActions = {
  login,
  logout,
};

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));

    accountService.login(username, password).then(
      (token) => {
        dispatch(success(token));
        history.push("/");
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(token) {
    return { type: accountConstants.LOGIN_REQUEST, token };
  }
  function success(token) {
    return { type: accountConstants.LOGIN_SUCCESS, token };
  }
  function failure(error) {
    return { type: accountConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  accountService.logout();
  return { type: accountConstants.LOGOUT };
}
