import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux'
import {Grid, makeStyles} from "@material-ui/core";
import InfoCard from "./InfoCard";
import {
    People as PeopleIcon,
    Public as PublicIcon,
    Room as RoomIcon,
    Smartphone as SmartphoneIcon,
} from "@material-ui/icons";
import {Loader} from '../../_theme/components/loader'
import {clientsActions, deviceActions, regionsActions, sitesActions, surfaceActions} from "../../_actions";

const useStyles = makeStyles((theme) => ({
    root: {
        // display: "flex",
        flexGrow: 1,
    },
}));

const ENTITY_TYPES = {
    CLIENTS: 'CLIENTS',
    SITES: 'SITES',
    DEVICES: 'DEVICES',
    REGIONS: 'REGIONS',
}

function Dashboard() {
    const classes = useStyles();
    const clients = useSelector(state => state.clients);
    const devices = useSelector(state => state.devices);
    const sites = useSelector(state => state.sites);
    const regions = useSelector(state => state.regions);
    const dispatch = useDispatch();


    function refresh() {
        dispatch(clientsActions.getList());
        dispatch(deviceActions.getAll());
        dispatch(sitesActions.getList());
        dispatch(regionsActions.getList());
    }

    useEffect(() => {
        dispatch(surfaceActions.setPageTitle("Admin"));
        refresh();
    }, []);

    /**
     * Based on type, it will return the count
     * @param type
     * @returns {string}
     */
    const getEntityCount = (type) => {
        switch (type) {
            case ENTITY_TYPES.CLIENTS:
                return clients.items ? clients.items.length : '';
            case ENTITY_TYPES.DEVICES :
                return devices.items ? devices.items.length : '';
            case ENTITY_TYPES.SITES :
                return sites.items ? sites.items.length : '';
            case ENTITY_TYPES.REGIONS:
                return regions.items ? regions.items.length : '';
        }

    }

    return (
        <div className={classes.root}>

            {clients.loading ?
                <Loader/>
                :
                <Grid container direction="column" spacing={2}>
                    <Grid
                        item
                        container
                        direction="row"
                        justify="space-evenly"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <InfoCard
                                title="Total Clients"
                                text={getEntityCount(ENTITY_TYPES.CLIENTS)}
                                icon={PeopleIcon}
                                link={'/admin/clients'}
                                linkText="View Client Details"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <InfoCard
                                title="Regions Covered"
                                text={getEntityCount(ENTITY_TYPES.REGIONS)}
                                icon={PublicIcon}
                                link={'/admin/regions'}
                                linkText="View Regions"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        direction="row"
                        justify="space-evenly"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <InfoCard
                                title="Total Sites"
                                text={getEntityCount(ENTITY_TYPES.SITES)}
                                icon={RoomIcon}
                                link={'/admin/sites'}
                                linkText="View Sites Details"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <InfoCard
                                title="Devices Assigned"
                                text={getEntityCount(ENTITY_TYPES.DEVICES)}
                                link={'/admin/clients'}
                                icon={SmartphoneIcon}
                                linkText="Device Details"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            }
        </div>
    );
}

export default Dashboard;
