import { alarmsConstants } from "../_constants/alarms.constants";

export function alarms(state = {}, action) {
  switch (action.type) {
    case alarmsConstants.GET_REQUEST:
      return {
        loading: true
      };
    case alarmsConstants.GET_SUCCESS:
      return {
        items: action.alarms
      };
    case alarmsConstants.GET_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
}
