import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import LabeledText from "../Common/labeledText";
import BatteryStatusView from "./BatteryStatusView";
import IsOnlineBadge from "./IsOnlineBadge";
import DateTimeView from "../Common/dateTimeView";
import DataTable from "../Common/dataTable";
import { useSelector, useDispatch } from "react-redux";
import { deviceActions } from "../_actions";

const useStyles = makeStyles(theme => ({
  root: {}
}));

function HistorySummary(props) {
  const { device, ...other } = props;

  // TODO
  const devices = useSelector(state => state.devices);
  const dispatch = useDispatch();

  function refresh() {
    dispatch(deviceActions.getAll());
  }

  useEffect(() => {
    refresh();
  }, []);

  const classes = useStyles();

  const columns = [
    {
      header: "Id",
      field: "id"
      // field: "SerialNumber"
    },
    {
      header: "Battery",
      field: "",
      template: BatteryStatusView,
      parameters: {
        value: Math.floor(Math.random() * 101)
      }
    },
    {
      header: "Online",
      field: "",
      template: IsOnlineBadge,
      parameters: {
        //   isOnline: "#field#",
        isOnline: Math.random() >= 0.5,
        iconOnly: true
      }
    },
    {
      header: "Narcotics Alert",
      field: "DateTime",
      template: DateTimeView,
      parameters: {
        dateTime: "#field#"
      }
    },
    {
      header: "Explosive Alerts",
      field: "DateTime",
      template: DateTimeView,
      parameters: {
        dateTime: "#field#"
      }
    }
  ];

  return (
    <div className={classes.root}>
      <DataTable
        dataCollection={devices}
        columns={columns}
        isDense={true}
        refreshCommand={refresh}
      />
    </div>
  );
}

HistorySummary.PropType = {
  device: PropTypes.object.isRequired
};

export default HistorySummary;
