import React from "react";
import {
  makeStyles,
  fade,
  Toolbar,
  AppBar,
  Typography,
  InputBase,
  IconButton,
} from "@material-ui/core";
import {
  Search as SearchIcon,
  Notifications as NotificationsIcon,
  Person as PersonIcon,
} from "@material-ui/icons";
import AppsMenu from "./AppsMenu";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    display: "none",
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function TopAppBar() {
  const surface = useSelector((state) => state.surface);

  const classes = useStyles();
  return (
    <AppBar position="absolute" color="primary" className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" noWrap>
          MS DETECTION
        </Typography>
        <Typography className={classes.title} noWrap>
          {surface.title}
        </Typography>
        <div className={classes.grow} />
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
          />
        </div>
        <AppsMenu />
        <IconButton color="inherit" variant="contained">
          <NotificationsIcon />
        </IconButton>
        <IconButton color="inherit" variant="contained">
          <PersonIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default TopAppBar;
