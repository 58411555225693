import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
  },
  label: {
      fontSize: 10,
      fontWeight: theme.typography.fontWeightBold,
  },
  text: {}
}));

function LabeledText(props) {
  const { label, text, ...other } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.label}>{label}</div>
      <div className={classes.text}>{text}</div>
    </div>
  );
}

LabeledText.PropType = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default LabeledText;
