import React, {Fragment, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from "@material-ui/core";
import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers';
import {clientsActions, regionsActions, surfaceActions} from "../../_actions";
import BackLinkButton from "../../_components/BackLinkButton";
import {FieldTypes} from "../../Common/constants";
import Field from "../../_components/Field";
import SubmitButton from "../../_components/SubmitButton";


const useStyles = makeStyles((theme) => ({
    fieldContainer: {
        textAlign: 'center'
    }
}));


const AddRegion = ({location}) => {

    const clients = useSelector(state => state.clients.items);
    const regionObj = useSelector(state => state.regions.item);
    const isUpdateFlow = location.pathname.includes('update');
    const {regionId} = useParams();
    const dispatch = useDispatch();
    const classes = useStyles();

    const validationSchema = yup.object().shape({
        Name: yup.string().required('Username is required'),
        Description: yup.string().required('Description is required'),
        Client: yup.mixed().required('Client is required')
    });

    const getDefaultFormValues = () => {
        return {
            Name: regionObj?.Name,
            Description: regionObj?.Description,
            Client: regionObj ? regionObj.Client : null
        }
    }
    const {register, handleSubmit, errors, control, formState, setValue, reset} = useForm({
        defaultValues: getDefaultFormValues(),
        mode: 'onTouched',
        resolver: yupResolver(validationSchema),

    });


    const submitHandler = (data) => {
        data['ClientId'] = data.Client?.ClientId;
        delete data['Client'];
        if (regionId) {
            data['RegionId'] = regionId;
            dispatch(regionsActions.save(data, true));
        } else {
            dispatch(regionsActions.save(data, false));
        }
    };

    useEffect(() => {
        dispatch(clientsActions.getList())
        if (regionId) {
            dispatch(regionsActions.getSingle(regionId));
            dispatch(surfaceActions.setPageTitle("Update Region"));
        } else {
            dispatch(surfaceActions.setPageTitle("Add Region"));
        }
    }, [])

    //Setting loaded region object for update case
    useEffect(() => {
        if (regionObj) {
                reset(regionObj);
        }
    }, [regionObj])


    const getClientOptions = () => {
        if (!clients) return [];
        return clients.map((client) => {
            return {
                Name: client.Name,
                ClientId: client.sk
            };
        });
    };

    return (

        <Fragment>
            <Box>
                <BackLinkButton path={'/admin/regions'}/>
                <Container maxWidth='sm'>
                    <form onSubmit={handleSubmit(submitHandler)} autoComplete='off'>
                        <Box className={classes.fieldContainer}>
                            <Field
                                type={FieldTypes.TEXT_FIELD}
                                name='Name'
                                register={register}
                                errors={errors}
                                isUpdateFlow={isUpdateFlow}
                            />
                            <Field
                                type={FieldTypes.SELECT_FIELD}
                                name='Client'
                                control={control}
                                errors={errors}
                                dropdownOptions={getClientOptions()}
                                dropdownOptionLabel={option => option.Name}
                                dropdownOptionSelected={(option, value) => option.ClientId === value.ClientId}
                                label='Select Client'
                            />
                            <Field
                                type={FieldTypes.TEXT_AREA}
                                name='Description'
                                register={register}
                                errors={errors}
                                isUpdateFlow={isUpdateFlow}
                            />

                            <SubmitButton
                                formState={formState}
                                label={isUpdateFlow ? 'UPDATE' : 'ADD'}
                            />
                        </Box>
                    </form>
                </Container>
            </Box>
        </Fragment>
    );


};

export default AddRegion;