import {deviceConstants} from "../_constants/";

export function devices(state = {}, action) {
    switch (action.type) {
        case deviceConstants.REQUEST:
            return {
                loading: true,
            };
        case deviceConstants.GET_ALL_SUCCESS:
            return {
                items: action.devices,
            };
        case deviceConstants.SAVE_SUCCESS:
            return {
                item: action.device,
            };
        case deviceConstants.GET_SINGLE_SUCCESS:
            return {
                item: action.device.data,
            };
        case deviceConstants.FAILURE:
            return {
                error: action.error,
            };
        default:
            return state;
    }
}

