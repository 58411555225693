export function getPropByString(obj, path) {
  if (Object.keys(obj).length === 0 && obj.constructor === Object) return;

  if (!path) return;

  path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  path = path.replace(/^\./, ""); // strip a leading dot

  const propKeys = path.split(".");
  for (let i = 0, n = propKeys.length; i < n; ++i) {
    let propKey = propKeys[i];
    if (propKey in obj) {
      obj = obj[propKey];
    } else {
      return;
    }
  }
  return obj;
}

export function adopteParams(initialObj, fieldValue) {
  let key = Object.keys(initialObj).find(key => initialObj[key] === "#field#");
  let params = { ...initialObj };
  params[key] = fieldValue;
  return params;
}


export const getUserOptions = (users) => {
  if (!users) return [];
  return users.map((user) => {
    return {Name: user.Name, User: user.User};
  });
};

export const getRegionOptions = (regions) => {
  if (!regions) return [];
  return regions.map((region) => {
    return {
      RegionId: region.sk,
      Name: region.Name,
      ClientId: region.ClientId
    }
  });
};

export const getSiteOptions = (sites) => {
  if (!sites) return [];
  return sites.map((site) => {
    return {
      SiteId: site.sk,
      Name: site.Name,
      ClientId: site.Client?.ClientId
    }
  });
};

export const getSitesByClient = (sites, clientId) => {
  if (!sites) return [];
  return sites.filter(site => {
    return site.Client?.ClientId === clientId
  }).map((site) => {
    return {
      SiteId: site.sk,
      Name: site.Name,
      ClientId: site.Client?.ClientId
    }
  });
}

export const getSitesByClients = (sites, clients) => {
  if (!sites) return [];
  const clientIds = clients.map(client => client.ClientId);
  return sites.filter(site => {
    return clientIds.includes(site.Client?.ClientId);
  }).map((site) => {
    return {
      SiteId: site.sk,
      Name: site.Name,
      ClientId: site.Client?.ClientId
    }
  });
}

export const getRegionsByClient = (regions, clientId) => {
  if (!regions) return [];
  return regions.filter(region => region.Client?.ClientId === clientId)
}


export const getClientOptions = (clients) => {
  if (!clients) return [];
  return clients.map((client) => {
    return {
      Name: client.Name,
      ClientId: client.sk
    };
  });
};
