import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {},
  badge: {
    borderRadius: "50%",
    width: "10px",
    height: "10px",
    margin: "auto 4px",
    display: "inline-block"
  },
  online: {
    background: "#44aa44"
  },
  offline: {
    background: "#ccc"
  }
}));

function IsOnlineBadge(props) {
  const { isOnline, iconOnly, ...other } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.badge, {
          [classes.online]: isOnline,
          [classes.offline]: !isOnline
        })}
      ></div>
      {!iconOnly && (isOnline ? "Online" : "Offline")}
    </div>
  );
}

IsOnlineBadge.PropType = {
  isOnline: PropTypes.bool.isRequired
};

export default IsOnlineBadge;
