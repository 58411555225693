import React from "react";
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
    withStyles,
} from "@material-ui/core";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import {Loader} from "../_theme/components/loader";
import TablePagination from "@material-ui/core/TablePagination";
import Container from "@material-ui/core/Container";
import ActionsMenu from "./ActionMenu";
import _get from 'lodash.get';

const useStyles = makeStyles((theme) => ({
    table: {
        borderSpacing: '0px 5px',
        borderCollapse: 'separate'
    },
    tableHeader: {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 12
    },
    tableHeaderCell: {
        border: 'none'
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    spacer: {
        flex: '0 0 0%'
    },
    toolbar: {
        justifyContent: 'center'
    }
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        margin: theme.spacing(1),
    },
}))(TableRow);

/*********Sorting functions starts **********/
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getAttributeValue(column, row) {
    let value;
    if(column.field && column.array && column.nested){
        const arrayForm =_get(row, column.field);
        if(arrayForm){
            const values = arrayForm.map(obj => obj[column.nestedObjAttribute]);
            value = values ? values.join(', ') : '';
        }
    }else if(column.field && column.array) {
        const arrayForm =_get(row, column.field);
        value = arrayForm ? arrayForm.join(', ') : '';
    }else if(column.field ){
        value = _get(row, column.field);
    }

    return value;
}

/*********Sorting functions ends**********/


function DataTable(props) {
    const {dataCollection, columns} = props;
    const [order, setOrder] = React.useState("");
    const [orderBy, setOrderBy] = React.useState("");

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const classes = useStyles();
    return (
        <div>
            {dataCollection.loading && <Loader/>}
            {dataCollection.error && (
                <Typography style={{textAlign: "center"}}>
                    <ErrorOutline color="primary" fontSize="large"/>
                    <div>An error occured</div>
                </Typography>
            )}
            {/* {columns && dataCollection.items && ( */}
            {!dataCollection.loading && columns && (
                <div>
                    <TableContainer>
                        <Table className={classes.table} size="small">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column, index) => {
                                        // const isNumeric = false;
                                        // const isPaddingDisabled = false;
                                        return (
                                            <TableCell
                                                key={index}
                                                align={column.isNumeric ? "right" : "left"}
                                                sortDirection={orderBy === column.field ? order : false}
                                                className={classes.tableHeaderCell}
                                            >
                                                {column.disableSorting ?
                                                    <Typography
                                                        color="primary"
                                                        className={classes.tableHeader}
                                                    >
                                                        {column.header}
                                                    </Typography>
                                                    :
                                                    <TableSortLabel
                                                        active={orderBy === column.field}
                                                        direction={orderBy === column.field ? order : "asc"}
                                                        onClick={() => handleRequestSort(column.field)}
                                                    >
                                                        <Typography
                                                            color="primary"
                                                            className={classes.tableHeader}
                                                        >
                                                            {column.header}
                                                        </Typography>
                                                    </TableSortLabel>
                                                }

                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataCollection.items &&
                                stableSort(dataCollection.items, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => (
                                        <StyledTableRow key={index} hover>
                                            {columns.map((column, index) => (
                                                <TableCell key={row.id + "_" + index}>
                                                    {column.actions &&
                                                    <ActionsMenu identifier={_get(row, column.field)}
                                                                 actions={column.actions}
                                                    />
                                                    }
                                                    {!column.actions && (
                                                        <div>
                                                            {getAttributeValue(column, row)}
                                                        </div>
                                                    )}
                                                </TableCell>
                                            ))}
                                        </StyledTableRow>
                                    ))}

                                {!dataCollection.loading && dataCollection?.items?.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Typography
                                                variant="overline"
                                                display="block"
                                                gutterBottom
                                                style={{textAlign: "center"}}
                                            >
                                                No records to display
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {dataCollection.items && dataCollection.items.length > 0 && (
                        <Container maxWidth={"sm"}>
                            <TablePagination
                                backIconButtonText="Previous"
                                backIconButtonProps={{color: "primary"}}
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={dataCollection.items.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                classes={{
                                    spacer: classes.spacer,
                                    toolbar: classes.toolbar,
                                }}
                            />
                        </Container>
                    )}
                </div>
            )}
        </div>
    );
}

DataTable.PropType = {};

export default DataTable;
