import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import DevicesMapView from "../Devices/DevicesMapView";
import DevicesTableView from "../Devices/DevicesTableView";
import AlarmsLocationSpecific from "../Alarms/AlarmsLocationSpecific";
import {
  Typography,
  Tabs,
  Tab,
  Box,
  makeStyles,
  withStyles
} from "@material-ui/core";

const StyledTabs = withStyles(theme => ({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {
      width: "100%",
      backgroundColor: theme.palette.primary.main
    }
  }
}))(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      opacity: 1
    },
    "&$selected": {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium
    },
    "&:focus": {
      color: theme.palette.primary.main
    }
  },
  selected: {}
}))(props => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

function LocationOverviewPage() {
  const { locationId } = useParams();
  const locationName = locationId;

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h5">{locationName}</Typography>

      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <StyledTab label="Geo Location" {...a11yProps(0)} />
        <StyledTab label="Alarms" {...a11yProps(1)} />
        <StyledTab label="Status" {...a11yProps(2)} />
      </StyledTabs>

      <TabPanel value={value} index={0}>
        <DevicesMapView />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AlarmsLocationSpecific />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DevicesTableView />
      </TabPanel>
    </div>
  );
}

export default LocationOverviewPage;
