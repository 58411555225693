import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import {
  makeStyles,
  Typography,
  Grid,
  Button,
  Container,
  Paper,
  Divider
} from "@material-ui/core";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import InfoSummary from "./InfoSummary";
import DeviceAlarmSummary from "./DeviceAlarmSummary";
import FactorySummary from "./FactorySummary";
import SingleLocationMap from "./SingleLocationMap";
import SensorOutputSummary from "./SensorOutputSummary";
import HistorySummary from "./HistorySummary";
import { history } from "../_helpers";

const useStyles = makeStyles(theme => ({
  root: {},
  markupGrid: {
    background: "#cecece",
    border: "1px solid #cecece"
  },
  vertSeparator: {
    width: "1px"
  },
  horSeparator: {
    height: "1px"
  },
  gridCell: {
    background: theme.palette.common.white,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(1)
    // margin: "1px"
  }
}));

function DeviceOverviewPage(props) {
  const { deviceId, ...other } = props;
  const classes = useStyles();

  const device = {};

  function goToDeviceList() {
    var locationId = "utah";
    history.push("/location/" + locationId);
  }

  return (
    <div className={classes.root}>
      <Button
        color="primary"
        startIcon={<ArrowBackIos />}
        onClick={goToDeviceList}
      >
        Device List
      </Button>

      <Grid container spacing={0} className={classes.markupGrid}>
        <Grid item className={classes.gridCell}>
          <Typography className={classes.blueHeader} color="primary">
            Device Info
          </Typography>
          <InfoSummary device={device} />
        </Grid>
        <Grid item className={classes.vertSeparator}></Grid>
        <Grid item xs={10} sm container>
          <Grid item xs container direction="column" spacing={0}>
            <Grid item xs container spacing={0}>
              <Grid item xs className={classes.gridCell}>
                <Typography variant="h6" color="primary">
                  Alarm Screen
                </Typography>
                <DeviceAlarmSummary device={device} />
              </Grid>
              <Grid item className={classes.vertSeparator}></Grid>
              <Grid item xs={7} container direction="column">
                <Grid
                  item
                  xs
                  className={classes.gridCell}
                  style={{ padding: "0" }}
                >
                  <div style={{ height: "36vh", width: "100%" }}>
                    <SingleLocationMap />
                  </div>
                </Grid>
                <Grid item className={classes.horSeparator}></Grid>
                <Grid item xs container>
                  <Grid item xs className={classes.gridCell}>
                    <Typography className={classes.blueHeader} color="primary">
                      Sensor output
                    </Typography>
                    <SensorOutputSummary device={device} />
                  </Grid>
                  <Grid item className={classes.vertSeparator}></Grid>
                  <Grid item xs className={classes.gridCell}>
                    <Typography>Photo & Video</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.vertSeparator}></Grid>
              <Grid item xs className={classes.gridCell}>
                <Typography className={classes.blueHeader} color="primary">
                  Factory
                </Typography>
                <FactorySummary device={device} />
              </Grid>
            </Grid>

            <Grid item className={classes.horSeparator}></Grid>
            <Grid item className={classes.gridCell}>
              <Typography className={classes.blueHeader} color="primary">
                History
              </Typography>
              <HistorySummary device={device} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

DeviceOverviewPage.PropType = {
  deviceId: PropTypes.string.isRequired
};

export default DeviceOverviewPage;
