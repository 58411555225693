import { createMuiTheme } from "@material-ui/core/styles";
// import * from 'material-ui/colors/bl';

const palette = {
  // type: 'dark',
  primary: {
    main: "#0079b2",
    dark: "#073d57",
    contrastText: "#fff",
  },
  secondary: {
    main: "#696969",
    dark: "#474141",
    light: "#e8e8e880",
    contrastText: "#fff",
  },
};

export default createMuiTheme({
  palette: palette,
  typography: {
    h6: {
      // color: theme.palette.primary.main,
      // textTransform: "uppercase",
      // fontWeight: theme.typography.fontWeightBold,
      // fontSize: 12
    },
    caption: {
      color: palette.secondary.dark,
      // textTransform: "uppercase",
      // fontWeight: theme.typography.fontWeightBold,
      fontSize: 10,
    },
  },
});
