import React from "react";
import PropTypes from "prop-types";
import { makeStyles, List, ListItem } from "@material-ui/core";
import LocationOn from "@material-ui/icons/LocationOn";
import LabeledText from "../Common/labeledText";
import LocationIcon from "../Common/locationIcon";

const useStyles = makeStyles(theme => ({
  root: {}
}));

function DeviceAlarmSummary(props) {
  const { device, ...other } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <LabeledText label="Detector S/N" text={device.serial}/> */}
      <LabeledText label="N/N" text="123972398342" />
      <LabeledText label="Location"/>
      <LocationIcon/>
      <LabeledText label="Alarm #" text="412" />
      <LabeledText label="Type" text="2.3" />
    </div>
  );
}

DeviceAlarmSummary.PropType = {
  device: PropTypes.object.isRequired
};

export default DeviceAlarmSummary;
