import {devicesService} from "../_services";
import {history} from "../_helpers";
import {alertActions} from "./alert.actions";
import {deviceConstants} from '../_constants'
import {loaderActions} from "./loader.actions";

function getAll() {

    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        devicesService.getAll().then(
            (response) => {
                let devices = response.data ? response.data.Items : [];
                dispatch(getAllSuccess(devices));
                dispatch(loaderActions.clear());
            }, (error) => dispatchError(error, dispatch));
    }

}


function getSingle(id) {
    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        devicesService.getSingle(id).then(
            (deviceResp) => {
                dispatch(getSingleSuccess(deviceResp));
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatchError(error, dispatch);
            });
    }
}


function save(data, isUpdate) {

    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        devicesService.save(data, isUpdate).then(
            (response) => {
                dispatch(saveSuccess(response));
                history.push('/admin/devices');
                const message = isUpdate ? 'Device updated successfully' : 'Device saved successfully';
                dispatch(alertActions.success(message))
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatchError(error, dispatch);
            }
        );
    };
}


function dispatchError(error, dispatch) {
    dispatch(failure(error.toString()));
    dispatch(alertActions.error(error.toString()));
    dispatch(loaderActions.clear());
}

function getAllSuccess(devices) {

    return {type: deviceConstants.GET_ALL_SUCCESS, devices};
}

function saveSuccess(device) {

    return {type: deviceConstants.SAVE_SUCCESS, device};
}

function getSingleSuccess(device) {

    return {type: deviceConstants.GET_SINGLE_SUCCESS, device};
}

function failure(error) {

    return {type: deviceConstants.FAILURE, error};
}

export const deviceActions = {
    getAll,
    save,
    getSingle
};
