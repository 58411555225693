import React from "react";
import PropTypes from "prop-types";
import * as moment from "moment";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {}
}));

function DateTimeView(props) {
  const { dateTime, ...other } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {moment(dateTime).format("DD/MM/YYYY HH:mm:ss")}
    </div>
  );
}

DateTimeView.PropType = {
  dateTime: PropTypes.any.isRequired
};

export default DateTimeView;
