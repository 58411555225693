import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { deviceActions } from "../_actions";

import { makeStyles } from "@material-ui/core";
import IsOnlineBadge from "./IsOnlineBadge";
import BatteryStatusView from "./BatteryStatusView";
import DataTable from "../Common/dataTable";
import DateTimeView from "../Common/dateTimeView";
import LocationIcon from "../Common/locationIcon";

const useStyles = makeStyles({
  table: {
    //   minWidth: 650,
  }
});

function DevicesTableView() {
  // const devices = useSelector(state => state.devices);
  const devices = useSelector(state => state);
  const dispatch = useDispatch();

  function refresh() {
    // dispatch(deviceActions.getAll());
  }

  useEffect(() => {
    refresh();
  }, []);

  const columns = [
    {
      header: "Id",
      field: "id"
      // field: "SerialNumber"
    },
    {
      header: "Battery",
      field: "",
      template: BatteryStatusView,
      parameters: {
        value: Math.floor(Math.random() * 101)
      }
    },
    {
      header: "Online",
      field: "",
      template: IsOnlineBadge,
      parameters: {
        //   isOnline: "#field#",
        isOnline: Math.random() >= 0.5,
        iconOnly: true
      }
    },
    {
      header: "Service",
      field: "DateTime",
      template: DateTimeView,
      parameters: {
        dateTime: "#field#"
      }
    },
    {
      header: "Location",
      field: "attributes.Coordinates",
      template: LocationIcon,
      parameters: {
        longitude: 56.63214,
        latitude: 53.88486,
        showMapOnHover: true
      }
    }
  ];

  const classes = useStyles();
  return (
    <div>
      <h4>Devices</h4>
      <DataTable
        dataCollection={devices}
        columns={columns}
        refreshCommand={refresh}
      />
    </div>
  );
}

export default DevicesTableView;
