import React, {useEffect} from "react";
import {useHistory} from 'react-router-dom';
import DataTable from "../../_components/DataTable";
import {useDispatch, useSelector} from "react-redux";
import {alertActions, sitesActions, surfaceActions} from "../../_actions";
import FloatingActionButton from "../../_components/FloatingActionButton";


const SiteList = () => {
    const sites = useSelector((state) => state.sites);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(surfaceActions.setPageTitle("Site List"));
        refresh();
    }, []);

    function refresh() {
        dispatch(sitesActions.getList());
        dispatch(alertActions.clear());
    }

    /**Functions for action menus **/
    const viewSite = (closeHandler, siteId) => {
        closeHandler();
        alert(siteId);
    }

    const viewOnMap = (closeHandler, siteId) => {
        closeHandler();
        alert(siteId);
    }

    const editSite = (closeHandler, siteId) => {
        closeHandler();
        history.push(`/admin/site/update/${siteId}`);
    }
    const inActivateSite = (closeHandler, siteId) => {
        closeHandler();
        alert(siteId);
    }
    const deleteSite = (closeHandler, siteId) => {
        closeHandler();
        alert(siteId);

    }


    const columns = [
        {
            header: "Site",
            field: "Name",
        },
        {
            header: "Client",
            field: "Client.Name",
        },
        {
            header: "Region",
            field: "Region.Name",
        },
        // {
        //     header: "User",
        //     field: "Users",
        //     array: true
        // },
        {
            header: "Description",
            field: "Description",
        },
        {
            header: "Actions",
            field: "sk",
            disableSorting: true,
            actions: [
                {clickListener: viewSite, label: 'View'},
                {clickListener: viewOnMap, label: 'View on Map'},
                {clickListener: editSite, label: 'Edit'},
                {clickListener: inActivateSite, label: 'Inactivate'},
                {clickListener: deleteSite, label: 'Delete'}
            ],
            parameters: {
                clientId: "#field#",
            },
        },
    ];

    return (
        <div>
            {
                sites ?
                    <DataTable dataCollection={sites} columns={columns}></DataTable>
                    : null
            }

            <FloatingActionButton path={'/admin/site/new'}/>

        </div>
    );
}

export default SiteList;
