import {regionsService} from "../_services";
import {regionsConstants} from "../_constants";
import {alertActions} from "./alert.actions";
import {history} from "../_helpers";
import {loaderActions} from "./loader.actions";


function getList() {
    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        regionsService.getAll().then(
            (response) => {
                let regions = response.data ? response.data.Items : [];
                dispatch(getAllSuccess(regions));
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatchError(error, dispatch);
            }
        );
    };
}

function getSingle(id) {
    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        regionsService.getSingle(id).then(
            (response) => {
                dispatch(getSingleSuccess(response));
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatchError(error, dispatch);
            }
        );
    };

}


function save(data, isUpdate) {
    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        regionsService.save(data, isUpdate).then(
            (response) => {
                dispatch(saveSuccess(response));
                history.push('/admin/regions');
                const message = `Region ${isUpdate ? 'updated' : 'saved'} successfully`;
                dispatch(alertActions.success(message));
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatchError(error, dispatch);
            }
        );
    };
}

function dispatchError(error, dispatch) {
    dispatch(failure(error.toString()));
    dispatch(alertActions.error(error.toString()));
    dispatch(loaderActions.clear());
}


function getAllSuccess(regions) {
    return {type: regionsConstants.GET_ALL_SUCCESS, regions};
}

function saveSuccess(region) {
    return {type: regionsConstants.SAVE_SUCCESS, region};
}

function getSingleSuccess(region) {
    return {type: regionsConstants.GET_SINGLE_SUCCESS, region};
}

function failure(error) {
    return {type: regionsConstants.FAILURE, error};
}


export const regionsActions = {
    getList,
    getSingle,
    save
};


