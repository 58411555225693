import React from "react";
import { Link } from "react-router-dom";
import {
  makeStyles,
  Paper,
  Grid,
  IconButton,
  Popover,
  Typography,
  ButtonBase,
  Box,
} from "@material-ui/core";
import {
  Dashboard as DashboardIcon,
  SupervisorAccount as SupervisorAccountIcon,
  Apps as AppsIcon,
  MoreHoriz as MoreHorizIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {},
  iconContainer: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  label: {
    fontSize: 10,
  },
  icon: {
    margin: "auto",
    display: "flex"
  },
}));

//Routes configuration for menu item
const menuRoutes = {
  dashboard: {label: 'Dashboard', routeTo: '/', icon: DashboardIcon},
  admin: {label: 'Administration', routeTo: '/admin', icon: SupervisorAccountIcon},
  more: {label: 'More', routeTo: '/', icon: MoreHorizIcon},
}


function AppsMenu(props) {
  const { ...other } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const classes = useStyles();

  const AppIcon = ({label, routeTo, icon: IconComponent})=> {
    const classes = useStyles();

    return (
        <ButtonBase focusRipple className={classes.iconContainer} component={Link} to={routeTo} onClick={() => setAnchorEl(null)}>
          <Box>
            <IconComponent className={classes.icon} fontSize='large'/>
            <Typography variant="button" display="block" className={classes.label}>
              {label}
            </Typography>
          </Box>
        </ButtonBase>
    );
  }

  return (
    <div className={classes.root}>
      <IconButton color="inherit" variant="contained" onClick={handleOpenMenu}>
        <AppsIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Paper>
          <Grid container>
            <Grid item xs>
              {AppIcon(menuRoutes.dashboard) }
            </Grid>
            <Grid item xs>
              {AppIcon(menuRoutes.admin)}
            </Grid>
            <Grid item xs>
              {AppIcon(menuRoutes.more)}
            </Grid>
          </Grid>
        </Paper>
      </Popover>
    </div>
  );
}

export default AppsMenu;
