import {authHeader} from "../_helpers";
import {handleResponse} from "./helper.service";
import config from "../config";

function getAll() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.getAdminApiBaseUrl()}/users`, requestOptions).then(
        handleResponse
    );
}

function getSingle(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.getAdminApiBaseUrl()}/users/${id}`, requestOptions).then(
        handleResponse
    );
}

const save = (userJson, isUpdate) => {
    let url;

    if (isUpdate) {
        url = `${config.getAdminApiBaseUrl()}/users/${userJson.User}`;
        // converting into desired format
        //e.g {update: {key:value}}
        userJson = {
            update: {
                ...userJson
            }
        }
    } else {
        url = `${config.getAdminApiBaseUrl()}/users`;
    }

    const requestOptions = {
        method: isUpdate ? 'PUT' : 'POST',
        headers: authHeader(),
        body: JSON.stringify(userJson)
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
}

export const usersService = {
    getAll,
    getSingle,
    save
};
