import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import LabeledText from "../Common/labeledText";

const useStyles = makeStyles(theme => ({
  root: {}
}));

function SensorOutputSummary(props) {
  const { device, ...other } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <LabeledText label="Detector S/N" text={device.serial}/> */}
      <LabeledText label="Sample Count" text="42" />
      <LabeledText label="Clear Reuslts" text="36" />
      <LabeledText label="Explosive Results" text="25" />
    </div>
  );
}

SensorOutputSummary.PropType = {
  device: PropTypes.object.isRequired
};

export default SensorOutputSummary;
