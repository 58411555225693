import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  withStyles,
  TableCell,
  TableBody,
  Button,
  Grid,
  Typography,
  IconButton
} from "@material-ui/core";
import Sync from "@material-ui/icons/Sync";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import clsx from "clsx";
import { Loader } from "../_theme/components/loader";
import * as helpers from "./helpers";

const StyledTableCell = withStyles(theme => ({
  head: {
    //   backgroundColor: theme.palette.common.black,
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    fontSize: 12
  },
  body: {
    fontSize: 12
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  blueHeader: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    fontWeight: theme.typography.fontWeightBold
  }
}));

function DataTable(props) {
  const {
    dataCollection,
    columns,
    isDense,
    title,
    refreshCommand,
    ...other
  } = props;
  const classes = useStyles();

  
  function refreshTableData() {
    refreshCommand && refreshCommand();
  }

  return (
    <div>
      {dataCollection.loading && <Loader />}
      {dataCollection.error && (
        <Typography style={{ textAlign: "center" }}>
          <ErrorOutline color="primary" fontSize="large" />
          <div>An error occured</div>
        </Typography>
      )}
      {dataCollection.items && columns && (
        <div>
          <Grid
            container
            alignItems="flex-end"
            justify="space-between"
            direction="row"
          >
            {title ? (
              <Typography className={classes.blueHeader}>{title}</Typography>
            ) : (
              <div></div>
            )}
            <Button
              size="small"
              color="default"
              className={classes.button}
              startIcon={<Sync />}
              onClick={refreshTableData}
            >
              Refresh
            </Button>
          </Grid>
          <TableContainer>
            <Table
              className={classes.table}
              size={isDense ? "small" : "medium"}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <StyledTableCell key={index}>
                      {column.header}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataCollection.items.map(row => (
                  <TableRow key={row.id} hover>
                    {columns.map((column, index) => (
                      <TableCell key={row.id + "_" + index}>
                        <div>
                          {column.parameters &&
                            React.createElement(
                              column.template,
                              helpers.adopteParams(
                                column.parameters,
                                helpers.getPropByString(row, column.field)
                              )
                            )}
                          {!column.template &&
                            column.field &&
                            helpers.getPropByString(row, column.field)}
                          {/* { column.template || helpers.getPropByString(row, column.field)} */}
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}

DataTable.PropType = {};

export default DataTable;
