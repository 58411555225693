import { surfaceConstants } from "../_constants";

export function surface(state = { drawerOpen: true }, action) {
  switch (action.type) {
    case surfaceConstants.TOGGLEDRAWER:
      return { ...state, drawerOpen: !state.drawerOpen };
    case surfaceConstants.SETTITLE:
      return { title: action.title };
    default:
      return state;
  }
}
