import React from "react";
import PropTypes from "prop-types";
import { makeStyles, List, ListItem } from "@material-ui/core";
import LocationOn from "@material-ui/icons/LocationOn";
import LabeledText from "../Common/labeledText";

const useStyles = makeStyles(theme => ({
  root: {}
}));

function InfoSummary(props) {
  const { device, ...other } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <LabeledText label="Detector S/N" text={device.serial}/> */}
      <LabeledText label="Detector S/N" text="123972398342" />
      <LabeledText label="Location" text="123" />
      <LabeledText label="Internet IP" text="123.123.123" />
      <LabeledText label="Software Version" text="2.3" />
      <LabeledText label="Firmware Version" text="4.1" />
      <LabeledText label="Explosive Algorithm Version" text="9827349283" />
      <LabeledText label="Narcotic Algorithm Version" text="123798289" />
      <LabeledText label="Telit Modem Version" text="234293827" />
      <LabeledText label="Matrix Status" text="76273649238" />
    </div>
  );
}

InfoSummary.PropType = {
  device: PropTypes.object.isRequired
};

export default InfoSummary;
