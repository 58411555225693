import React from "react";
import Drawer from "@material-ui/core/Drawer";
import {
  makeStyles,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  InputBase,
  Collapse,
  ListItemSecondaryAction,
  Toolbar,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { history } from "../_helpers";
import { accountActions } from "../_actions";
import { useDispatch, useSelector } from "react-redux";
import RegionsList from "./RegionsList";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    whiteSpace: "nowrap",
    textTransform: "uppercase",
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  searchInputPaper: {
    margin: theme.spacing(1),
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  listMain: {
    height: "100%",
    overflow: "auto",
  },
  internalListMain: {
    background: "#474141",
    textTransform: "none",
  },
  listBottom: {
    background: theme.palette.primary.dark,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function AppDrawer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [toggleOpen, setToggleOpen] = React.useState(false);
  const [selectedListItem, setSelectedListItem] = React.useState("dashboard");

  const surface = useSelector((state) => state.surface);

  const handleToggleClick = () => {
    setToggleOpen(!toggleOpen);
  };
  const handleListItemClick = (event, key, route) => {
    setSelectedListItem(key);
    if (route) history.push(route);
  };

  function signOut() {
    dispatch(accountActions.logout());
    history.push("/");
  }

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: surface.drawerOpen,
        [classes.drawerClose]: !surface.drawerOpen,
      })}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: surface.drawerOpen,
          [classes.drawerClose]: !surface.drawerOpen,
        }),
      }}
    >
      <Toolbar />

      {surface.drawerOpen && (
        <Paper component="form" className={classes.searchInputPaper}>
          <InputBase
            className={classes.input}
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
          />
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      )}

      <RegionsList/>

      <List className={classes.listMain}>
        {false && surface.drawerOpen && (
          <div>
            <ListItem
              button
              key="dashboard"
              onClick={(event) => handleListItemClick(event, "dashboard", "/")}
              selected={selectedListItem === "dashboard"}
            >
              <ListItemText primary="Dashboard" />
            </ListItem>

            {/* <Divider /> */}

            <ListItem
              button
              onClick={(event) => {
                handleListItemClick(event, "utah", "/location/utah");
              }}
              selected={selectedListItem === "utah"}
            >
              <ListItemText primary="Utah" />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="expand"
                  onClick={handleToggleClick}
                >
                  {toggleOpen ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>

            <Collapse in={toggleOpen} timeout="auto" unmountOnExit>
              <List
                className={classes.internalListMain}
                component="div"
                disablePadding
              >
                {["Device1", "Device2", "Device3", "Device4"].map(
                  (text, index) => (
                    <ListItem
                      button
                      key={text}
                      className={classes.nested}
                      onClick={(event) => {
                        handleListItemClick(
                          event,
                          "utah-" + text,
                          "/device/" + text
                        );
                      }}
                      selected={selectedListItem === "utah-" + text}
                    >
                      <ListItemText primary={text} />
                    </ListItem>
                  )
                )}
              </List>
            </Collapse>

            {["Georgia", "Florida", "California"].map((text, index) => (
              <ListItem
                button
                key={text}
                onClick={(event) =>
                  handleListItemClick(event, text, "/location/" + text)
                }
                selected={selectedListItem === text}
              >
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </div>
        )}
      </List>

      <Divider />

      <List className={classes.listBottom}>
        <ListItem button key="Settings">
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
        <ListItem button key="Sign Out" onClick={signOut}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </ListItem>
      </List>
    </Drawer>
  );
}

export default AppDrawer;
