import { alarmsConstants } from "../_constants";
import { alarmsService } from "../_services";
import { alertActions } from "./";

export const alarmsActions = {
  getAll,
  getByDevice,
  getByLocation
};

function getAll() {
  return dispatch => {
    dispatch(request());

    alarmsService.getAll().then(
      response => dispatch(success(response.data.Items)),
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function getByDevice(deviceId) {
  return dispatch => {
    dispatch(request());

    alarmsService.getDeviceSpecific(deviceId).then(
      response => dispatch(success(response.data.Items)),
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function getByLocation(locationId) {
  return dispatch => {
    dispatch(request());

    alarmsService.getLocationSpecific(locationId).then(
      response => dispatch(success(response.data.Items)),
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function request() {
  return { type: alarmsConstants.GET_REQUEST };
}
function success(alarms) {
  return { type: alarmsConstants.GET_SUCCESS, alarms };
}
function failure(error) {
  return { type: alarmsConstants.GET_FAILURE, error };
}
