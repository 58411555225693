import { surfaceConstants } from "../_constants";

export const surfaceActions = {
  toggleDrawer,
  setPageTitle,
};

function toggleDrawer() {
  return { type: surfaceConstants.TOGGLEDRAWER };
}

function setPageTitle(title) {
  return { type: surfaceConstants.SETTITLE, title };
}
