import {userConstants} from "../_constants";
import {usersService} from "../_services";
import {history} from "../_helpers";
import {alertActions, loaderActions} from './'

function getList() {

    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        usersService.getAll().then(
            (response) => {
                let users = response.data ? response.data.Items : [];
                dispatch(getAllSuccess(users));
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatchError(error, dispatch);
            }
        );
    };
}


function getSingle(id) {
    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        usersService.getSingle(id).then(
            (response) => {
                dispatch(getSingleSuccess(response));
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatchError(error, dispatch);
            }
        );
    };

}

function save(data, isUpdate) {
    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        usersService.save(data, isUpdate).then(
            (response) => {
                dispatch(saveSuccess(response));
                history.push('/admin/users');
                const message = isUpdate ? 'User updated successfully' : 'User saved successfully';
                dispatch(alertActions.success(message));
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatchError(error, dispatch);
            }
        );
    };
}


function dispatchError(error, dispatch) {
    dispatch(failure(error.toString()));
    dispatch(alertActions.error(error.toString()));
    dispatch(loaderActions.clear());
}


function getAllSuccess(users) {
    return {type: userConstants.GET_ALL_SUCCESS, users};
}

function saveSuccess(user) {
    return {type: userConstants.SAVE_SUCCESS, user};
}

function getSingleSuccess(user) {
    return {type: userConstants.GET_SINGLE_SUCCESS, user};
}

function failure(error) {
    return {type: userConstants.FAILURE, error};
}


export const usersActions = {
    getList,
    save,
    getSingle,
};
