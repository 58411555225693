import React from "react";
import PropTypes from "prop-types";
import { makeStyles, LinearProgress, withStyles } from "@material-ui/core";
import { lightGreen, amber, red } from "@material-ui/core/colors";

const FullBatteryProgress = withStyles({
  colorPrimary: {
    backgroundColor: lightGreen[100]
  },
  barColorPrimary: {
    backgroundColor: lightGreen[400]
  }
})(LinearProgress);

const MostlyFullBatteryProgress = withStyles({
  colorPrimary: {
    backgroundColor: amber[100]
  },
  barColorPrimary: {
    backgroundColor: amber[400]
  }
})(LinearProgress);

const MostlyEmptyBatteryProgress = withStyles({
  colorPrimary: {
    backgroundColor: red[100]
  },
  barColorPrimary: {
    backgroundColor: red[400]
  }
})(LinearProgress);

const EmptyBatteryProgress = withStyles({
  colorPrimary: {
    backgroundColor: "#ccc"
  }
})(LinearProgress);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));

function BatteryStatusView(props) {
  const { value, ...other } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {value >= 80 && (
        <FullBatteryProgress variant="determinate" value={value} />
      )}

      {value < 80 && value >= 20 && (
        <MostlyFullBatteryProgress variant="determinate" value={value} />
      )}

      {value < 20 && value > 0 && (
        <MostlyEmptyBatteryProgress variant="determinate" value={value} />
      )}

      {value === 0 && (
        <EmptyBatteryProgress variant="determinate" value={value} />
      )}
    </div>
  );
}

BatteryStatusView.PropType = {
  value: PropTypes.number.isRequired
};

export default BatteryStatusView;
