import React from 'react';
import GoogleMapReact from 'google-map-react'
import {makeStyles} from '@material-ui/core';
import config from '../config';

const AnyReactComponent = ({ text }) => <div>{ text }</div>;

const useStyles = makeStyles(theme => ({
    root: {
    },
    map: {
    }
  }));

function DevicesMapView() {
    const defaultProps = {
        center: { lat: 40.7446790, lng: -73.9485420 },
        zoom: 11
      }

      const classes = useStyles();

    return (
        <div style={{ height: '76vh', width: '100%' }}>
          <GoogleMapReact
          className={classes.map}
            bootstrapURLKeys={ config.mapsKey }
            defaultCenter={ defaultProps.center }
            defaultZoom={ defaultProps.zoom }>
            <AnyReactComponent
              lat={ 40.7473310 }
              lng={ -73.8517440 }
              text={ "Where's Waldo?" }
            />
          </GoogleMapReact>
        </div>
    );
}

export default DevicesMapView;