import React, {useEffect} from "react";
import {useHistory} from 'react-router-dom';
import DataTable from "../../_components/DataTable";
import {useDispatch, useSelector} from "react-redux";
import {alertActions, surfaceActions, regionsActions} from "../../_actions";
import FloatingActionButton from "../../_components/FloatingActionButton";


const RegionList = () => {
    const regions = useSelector((state) => state.regions);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(surfaceActions.setPageTitle("Region List"));
        refresh();
    }, []);

    function refresh() {
        dispatch(regionsActions.getList());
        dispatch(alertActions.clear());
    }

    /**Functions for action menus **/
    const viewUser = (closeHandler, regionId) => {
        closeHandler();
        alert(regionId);
    }

    const editUser = (closeHandler, regionId) => {
        closeHandler();
        history.push(`/admin/region/update/${regionId}`);
    }
    const inActivateUser = (closeHandler, regionId) => {
        closeHandler();
        alert(regionId);
    }
    const deleteUser = (closeHandler, regionId) => {
        closeHandler();
        alert(regionId);

    }


    const columns = [
        {
            header: "Region",
            field: "Name",
        },
        {
            header: "Client",
            field: "Client.Name",
        },
        {
            header: "Description",
            field: "Description",
        },
        {
            header: "Actions",
            field: "sk",
            disableSorting: true,
            actions: [
                {clickListener: viewUser, label: 'View'},
                {clickListener: editUser, label: 'Edit'},
                {clickListener: inActivateUser, label: 'Inactivate'},
                {clickListener: deleteUser, label: 'Delete'}
            ],
            parameters: {
                clientId: "#field#",
            },
        },
    ];

    return (
        <div>
            {
                regions ?
                    <DataTable dataCollection={regions} columns={columns} ></DataTable>
                    : null
            }

            <FloatingActionButton path={'/admin/region/new'}/>

        </div>
    );
}

export default RegionList;
