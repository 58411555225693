import React, {useEffect} from "react";
import {useHistory} from 'react-router-dom';
import DataTable from "../../_components/DataTable";
import {useDispatch, useSelector} from "react-redux";
import {alertActions, surfaceActions, usersActions} from "../../_actions";
import FloatingActionButton from "../../_components/FloatingActionButton";


const SiteList = () => {
    const users = useSelector((state) => state.users);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(surfaceActions.setPageTitle("User List"));
        refresh();
    }, []);

    function refresh() {
        dispatch(usersActions.getList());
        dispatch(alertActions.clear());
    }

    /**Functions for action menus **/
    const viewUser = (closeHandler, userId) => {
        closeHandler();
        alert(userId);
    }

    const assignToSite = (closeHandler, userId) => {
        closeHandler();
        alert(userId);
    }

    const editUser = (closeHandler, userId) => {
        closeHandler();
        history.push(`/admin/user/update/${userId}`);
    }
    const inActivateUser = (closeHandler, userId) => {
        closeHandler();
        alert(userId);
    }
    const deleteUser = (closeHandler, userId) => {
        closeHandler();
        alert(userId);

    }


    const columns = [
        {
            header: "Username",
            field: "User",
        },
        {
            header: "Name",
            field: "Name",
        },
        {
            header: "Clients",
            field: "Clients",
            array: true,
            nested:true,
            nestedObjAttribute: 'Name'
        },
        {
            header: "Sites",
            field: "Sites",
            array: true,
            nested:true,
            nestedObjAttribute: 'Name'
        },
        {
            header: "Role",
            field: "Roles",
            array: true
        },
        {
            header: "Email",
            field: "Email",
        },
        {
            header: "Actions",
            field: "User",
            disableSorting: true,
            actions: [
                {clickListener: viewUser, label: 'View'},
                {clickListener: assignToSite, label: 'Assign to a Site'},
                {clickListener: editUser, label: 'Edit'},
                {clickListener: inActivateUser, label: 'Inactivate'},
                {clickListener: deleteUser, label: 'Delete'}
            ],
            parameters: {
                clientId: "#field#",
            },
        },
    ];

    return (
        <div>
            {
                users ?
                    <DataTable dataCollection={users} columns={columns}></DataTable>
                    : null
            }

            <FloatingActionButton path={'/admin/user/new'}/>

        </div>
    );
}

export default SiteList;
