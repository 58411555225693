import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import LabeledText from "../Common/labeledText";
import BatteryStatusView from "./BatteryStatusView";

const useStyles = makeStyles(theme => ({
  root: {}
}));

function FactorySummary(props) {
  const { device, ...other } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <LabeledText label="Detector S/N" text={device.serial}/> */}
      <LabeledText label="T1" text="42" />
      <LabeledText label="T2" text="36" />
      <LabeledText label="Battery" />
      <BatteryStatusView value={84} />
      <LabeledText label="Sensor Matrix" text="2.3" />
    </div>
  );
}

FactorySummary.PropType = {
  device: PropTypes.object.isRequired
};

export default FactorySummary;
