import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Paper, Typography, Grid } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  cardPaper: {
    padding: theme.spacing(1),
    height: "100%"
  },
  cardGrid: {
    height: "100%"
  },
  blueTitle: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    fontSize: 12,
    // fontWeight: theme.typography.fontWeightBold
  },
  blueText: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold
  },
}));

function InfoCard(props) {
  const { title, text, ...other } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.cardPaper}>
      <Grid
        container
        direction="column"
        justify="space-around"
        alignItems="stretch"
        className={classes.cardGrid}
      >
        <Grid item xs>
          <Typography variant="body2" className={classes.blueTitle}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="h5" className={classes.blueText}>
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

InfoCard.PropType = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default InfoCard;
