import React from "react";
import {
  makeStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  ListItem,
  ListItemText,
  List,
} from "@material-ui/core";
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ExpandLess } from "@material-ui/icons";

const renderDeviceList = (devices) => {
  return (
    <ExpansionPanelDetails>
      <List component="div" disablePadding>
        {devices.map((device, index) => {
          return (
            <ListItem button key={index}>
              <ListItemText primary={device.title} />
            </ListItem>
          );
        })}
      </List>
    </ExpansionPanelDetails>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

function RegionsList() {
  const [expandedRegion, setExpandedRegion] = React.useState(false);
  const [expandedSite, setExpandedSite] = React.useState(false);

  const handleChangeRegion = (panel) => (event, isExpanded) => {
    setExpandedRegion(isExpanded ? panel : false);
  };
  const handleChangeSite = (panel) => (event, isExpanded) => {
    setExpandedSite(isExpanded ? panel : false);
  };

  const mock = [
    {
      uniqueKey: "1",
      title: "New York",
      has2thLevel: false,
      items: [
        {
          uniqueKey: "1__1",
          title: "Device1",
        },
        {
          uniqueKey: "1__2",
          title: "Device2",
        },
      ],
    },
    {
      uniqueKey: "2",
      title: "West",
      has2thLevel: true,
      items: [
        {
          uniqueKey: "2_1",
          title: "California",
          items: [
            {
              uniqueKey: "2_1_1",
              title: "Device3",
            },
            {
              uniqueKey: "2_1_2",
              title: "Device4",
            },
            {
              uniqueKey: "2_1_3",
              title: "Device5",
            },
          ],
        },
        {
          uniqueKey: "2_2",
          title: "Alaska",
          items: [
            {
              uniqueKey: "2_2_1",
              title: "Device6",
            },
            {
              uniqueKey: "2_2_2",
              title: "Device7",
            },
            {
              uniqueKey: "2_2_3",
              title: "Device8",
            },
          ],
        },
      ],
    },
    {
      uniqueKey: "3",
      title: "Florida",
      has2thLevel: false,
      items: [
        {
          uniqueKey: "3__1",
          title: "Device9",
        },
      ],
    },
  ];

  const classes = useStyles();

  return <div className={classes.root}>
      <TreeView
      className={classes.root}
      defaultExpanded={['1']}
      defaultCollapseIcon={<ExpandLess/>}
      defaultExpandIcon={<ExpandMoreIcon />}
    ></TreeView>
  </div>;

  return (
    false && (
      <div className={classes.root}>
        {mock.map((level1Item, index) => {
          return (
            <ExpansionPanel
              key={index}
              expanded={expandedRegion === level1Item.uniqueKey}
              onChange={handleChangeRegion(level1Item.uniqueKey)}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                color="secondary"
              >
                <Typography>{level1Item.title}</Typography>
              </ExpansionPanelSummary>

              {level1Item.has2thLevel && (
                <ExpansionPanelDetails>
                  {level1Item.items.map((subDivision, subIndex) => {
                    return (
                      <ExpansionPanel
                        expanded={expandedRegion === subDivision.uniqueKey}
                        onChange={handleChangeRegion(subDivision.uniqueKey)}
                      >
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          color="secondary"
                        >
                          <Typography>subDivision.title</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails></ExpansionPanelDetails>
                      </ExpansionPanel>
                    );
                  })}
                </ExpansionPanelDetails>
              )}

              {!level1Item.has2thLevel && renderDeviceList(level1Item.items)}
            </ExpansionPanel>
          );
        })}
      </div>
    )
  );
}

export default RegionsList;
